const groupBy = (iterable, callbackfn) => {
  const obj = Object.create(null);
  let i = 0;
  for (const value of iterable) {
    const key = callbackfn(value, i++);
    key in obj ? obj[key].push(value) : (obj[key] = [value]);
  }
  return obj;
};

// TODO: remove when safari 17.3 (which have only Array.prototype.groupToMap) die
Object.groupBy = Object.groupBy || groupBy;
